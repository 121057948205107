:root {
  --scroll-bar-color: #309aa19a;
  --scroll-bar-bg-color: rgba(46, 99, 68, 0.12);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.recharts-wrapper {
  font-size: 0.65rem;
}

/* * {
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
} */

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  z-index: 5000;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 4px;
  border: 1px solid var(--scroll-bar-bg-color);
}

.toast-top-center {
  top: 225px !important;
}

#toast-container>div {
  opacity: 1 !important;
  max-width: 700px !important;
  line-height: 1.75;
}

#toast-container.toast-top-center>div {
  width: auto !important;
}

#enterprise-results-first-ref{
  scroll-margin-top: 5rem;
}

.temp-site-down-banner {
  top: 0;
  left: 0;
  width: auto;
  z-index: 1; 
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #ff9800;
  color: black;
  line-height: 1.4;
}